import { createEntityCollectionStore } from '@recruitee/ngrx';
import type { ReferralsTerm } from '@recruitee/referrals-types';

import { EntityStoreKey } from '../types';

const { actions, selectors, reducer, namespace } = createEntityCollectionStore<ReferralsTerm>()(
  EntityStoreKey.terms,
);

export {
  actions as TermsActions,
  reducer as termsReducer,
  selectors as termsSelectors,
  namespace as TermsNamespace,
};
