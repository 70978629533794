<div #header class="header-wrap" [class.hidden]="headerHidden">
  <rtr-header (logOut)="logOut()"></rtr-header>
  <rtr-breadcrumbs></rtr-breadcrumbs>
</div>

<div class="main-outlet" [class.has-breadcrumbs]="hasBreadcrumbs">
  @if (sidebarOpen) {
    <rtr-sidebar (logOut)="logOut()"></rtr-sidebar>
  }
  <rtc-detached-router-outlet></rtc-detached-router-outlet>
</div>
<rtr-footer></rtr-footer>
<div class="footer-sticky">
  <ng-container [ngTemplateOutlet]="templateRef"></ng-container>
</div>
