export enum EntityStoreKey {
  program = 'program',
}

export interface ReferralsPortalProgram {
  logoUrl: string;
  description: string;
  companyName: string;
  referralStatusVisible: boolean;
  rewardsVisible: boolean;
  connectingAccountsEnabled: boolean;
  cvUploadEnabled: boolean;
  companyLanguage: string;
  formSettings: FormSettings;
}

export interface FormSettings {
  cv: FieldSettings;
  phone: FieldSettings;
  socialLinks: FieldSettings;
  email: FieldSettings;
}

export enum FieldSettings {
  Required = 'required',
  Hidden = 'hidden',
  Optional = 'optional',
}

export interface ReferralsPortalProgramPayload {
  logo?: string | null;
  description?: string;
}
