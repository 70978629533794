export enum EntityStoreKey {
  core = 'core',
}

export type SignUpPayload = {
  registration: {
    email: string;
    emailCode?: string;
    firstName: string;
    lastName: string;
    password: string;
    token?: string;
  };
  'g-recaptcha-response': string;
};

export type AuthorizeConfirmationPayload = {
  confirmation: {
    token: string;
    emailCode: string;
  };
};

export type SignInPayload = {
  session: {
    email: string;
    password: string;
  };
};

export interface ICoreState {
  isForeignAdmin: boolean;
}

export type AuthConfirmationSuccessPayload = {
  accessToken: string;
  expiresAt: number;
  refreshToken: string | null;
  scope: string;
  tokenType: string;
};

export interface Credentials {
  token: string;
  emailCode: string;
}
