import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { noHTMLWhitespaceValidator, noWhitespaceValidator } from '@recruitee/common';
import { TranslatePipe } from '@recruitee/i18n';
import type { ReferralsTerm } from '@recruitee/referrals-types';
import { IconComponent, IconColorDirective, DividerComponent } from '@recruitee/user-interface';

import { ExternalUrl, IExternalUrl } from '../../../config';
import { TermsConditionsService } from '../../services/terms-conditions.service';

@Component({
  selector: 'rtr-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DividerComponent, IconColorDirective, IconComponent, TranslatePipe],
})
export class FooterComponent implements OnInit, OnDestroy {
  public terms: ReferralsTerm | null;
  public privacy: ReferralsTerm | null;
  public ExternalUrl: IExternalUrl = ExternalUrl;

  private ngOnDestroy$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private termsConditionsService: TermsConditionsService,
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.termsConditionsService.termsConditions$,
      this.termsConditionsService.privacyPolicy$,
    ])
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(terms => {
        [this.terms, this.privacy] = terms.map(term => {
          if (!term) {
            return null;
          }

          const bodyValid = new UntypedFormControl(term.bodyHtml, noHTMLWhitespaceValidator).valid;
          const linkValid =
            new UntypedFormControl(term.sourceLink, noWhitespaceValidator).valid &&
            term.linkEnabled;

          return bodyValid || linkValid ? term : null;
        });
        this.cdRef.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  public onTermsClick(): void {
    this.termsConditionsService.openTermsConditions();
  }

  public onPrivacyClick(): void {
    this.termsConditionsService.openPrivacyPolicy();
  }
}
