import { HttpErrorResponse } from '@angular/common/http';

import { createSingleEntityStore, ngrxEvent } from '@recruitee/ngrx';

import {
  AuthConfirmationSuccessPayload,
  AuthorizeConfirmationPayload,
  EntityStoreKey,
  ICoreState,
  SignInPayload,
  SignUpPayload,
} from '../types';

const { actions, selectors, reducer, namespace } = createSingleEntityStore<ICoreState>()(
  EntityStoreKey.core,
  {
    customActions: adapter => ({
      init: state => adapter.update(state, { isForeignAdmin: false }),
      navigateToRecruitee: ngrxEvent<void>(),
      signInWithRecruitee: ngrxEvent<void>(),
      signIn: ngrxEvent<SignInPayload>(),
      signInSuccess: ngrxEvent<void>(),
      signInFail: ngrxEvent<any>(),
      logOutWithRecruitee: ngrxEvent<void>(),
      logOut: ngrxEvent<void>(),
      logOutSuccess: ngrxEvent<void>(),
      signUp: ngrxEvent<SignUpPayload>(),
      signUpSuccess: ngrxEvent<void>(),
      signUpFail: ngrxEvent<HttpErrorResponse & { errorFields: any }>(),
      authorizeConfirmation: ngrxEvent<AuthorizeConfirmationPayload>(),
      authorizeConfirmationSuccess: ngrxEvent<AuthConfirmationSuccessPayload>(),
      authorizeConfirmationFail: ngrxEvent<any>(),
      toggleExternalAdmin: (state, payload) =>
        adapter.update(state, { isForeignAdmin: !state.data?.isForeignAdmin }),
    }),
  },
);

export {
  actions as CoreEntityActions,
  reducer as coreReducer,
  selectors as coreSelectors,
  namespace as CoreNamespace,
};
