<rt-dialog-header [heading]="title"></rt-dialog-header>
<div class="body">
  <rt-box>
    <div class="content" [innerHTML]="body" layout-full-width></div>
  </rt-box>
</div>
<rt-divider></rt-divider>
<div class="footer" space-lr padding-tb-small layout layout-justify-flex-end>
  <rt-button (click)="close()" variant="primary">
    {{ 'rtr.terms_conditions.got_it' | translate }}
  </rt-button>
</div>
