<ng-template #profileMenu let-toggleDropdown="toggleDropdown" let-dropdownOpened="dropdownOpened">
  <rt-button [dropdownOpened]="dropdownOpened" (click)="toggleDropdown(true)" variant="plain">
    @if (profile) {
      <div layout layout-align-items-center>
        <rt-avatar
          [type]="profile.avatarThumbUrl ? 'image' : 'initials'"
          [size]="'small'"
          [shape]="'oval'"
          [imageSrc]="profile.avatarThumbUrl"
          [fullName]="userName"
          [isColorized]="true"
          margin-right-small-x
        ></rt-avatar>
        <span class="user-name" text-semi-medium text-color> {{ userName }} </span>
      </div>
    }
  </rt-button>
</ng-template>

<ng-template #profileMenuOption let-option="option">
  <div class="profile-menu-item">
    <rt-icon [name]="option.icon" margin-right-small></rt-icon>
    <span text-semi-medium> {{ option.label }} </span>
  </div>
</ng-template>

<ng-template #loggedContext>
  <nav margin-medium>
    <rt-tabs class="nav-tabs">
      <rt-tab [routerLinkActiveOptions]="{ exact: true }" routerLink="/" routerLinkActive="current">
        <a>
          {{ 'rtr.homepage.menu.jobs' | translate }}
        </a>
      </rt-tab>
      <rt-tab
        [routerLinkActiveOptions]="{ exact: true }"
        routerLink="/referrals"
        routerLinkActive="current"
      >
        <a>
          {{ 'rtr.homepage.menu.referrals' | translate }}
        </a>
      </rt-tab>
      <rt-tab
        [routerLinkActiveOptions]="{ exact: true }"
        routerLink="/how-it-works"
        routerLinkActive="current"
      >
        <a>
          {{ 'rtr.homepage.menu.how_it_works' | translate }}
        </a>
      </rt-tab>
    </rt-tabs>
  </nav>
  <rt-icon-button
    [iconName]="sidebarOpen ? 'close' : 'menu'"
    (click)="toggleSidebar()"
    size="small"
    variant="plain"
    shape="square"
    class="menu-trigger"
  >
  </rt-icon-button>
  <rt-select
    class="profile-menu"
    [hasSearch]="false"
    [closeOnSelect]="true"
    [includeEmptyOption]="false"
    [minDropdownWidth]="150"
    [triggerTemplate]="profileMenu"
    [optionTemplate]="profileMenuOption"
    [options]="profileDropdownOptions"
    (valueChange)="onSelect($any($event))"
  >
  </rt-select>
</ng-template>

<ng-template #notLoggedContext>
  @if (sigInButtonActive) {
    <rtr-sign-in-button class="sign-in-button"></rtr-sign-in-button>
  } @else {
    <rtr-sign-up-button class="sign-up-button"></rtr-sign-up-button>
  }
</ng-template>

<div class="header" layout layout-align-items-center layout-space-between>
  @if (program) {
    <rtr-header-logo
      [logoUrl]="program.logoUrl"
      [companyName]="program.companyName"
    ></rtr-header-logo>
  }
  <ng-container
    *ngTemplateOutlet="
      headerContext === HeaderMenuContext.Logged ? loggedContext : notLoggedContext
    "
  ></ng-container>
</div>
<rt-divider></rt-divider>
