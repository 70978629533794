import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TranslatePipe } from '@recruitee/i18n';

@Component({
  selector: 'rtr-sign-in-button',
  templateUrl: './sign-in-button.component.html',
  styleUrls: ['./sign-in-button.component.less'],
  standalone: true,
  imports: [RouterLink, TranslatePipe],
})
export class SignInButtonComponent {}
