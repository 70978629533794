import { Injectable, TemplateRef } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StickyFooterService {
  private template: BehaviorSubject<TemplateRef<any> | null> = new BehaviorSubject(null);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public template$: Observable<TemplateRef<any> | null> = this.template.asObservable();

  public setStickyTemplate(template: TemplateRef<any>, until?: Observable<any>): void {
    this.template.next(template);

    if (until) {
      until.pipe(first()).subscribe(() => this.clearTemplate());
    }
  }

  public clearTemplate(): void {
    this.template.next(null);
  }
}
