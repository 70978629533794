import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { TranslatePipe } from '@recruitee/i18n';
import { HeadingComponent } from '@recruitee/user-interface';

import { MainViewEntityActions } from '../../../features/main-view/data/store/main-view.store';

@Component({
  selector: 'rtr-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadingComponent, RouterLink, RouterLinkActive, TranslatePipe],
})
export class SidebarComponent {
  @Output() public logOut: EventEmitter<void> = new EventEmitter();

  private ngOnDestroy$ = new Subject<void>();

  constructor(private store: Store) {}

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  public closeSidebar(): void {
    this.store.dispatch(MainViewEntityActions.toggleSidebar());
  }
}
