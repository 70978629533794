import { BreakpointObserver } from '@angular/cdk/layout';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ChildActivationEnd, Router, RouterLinkActive, RouterLink } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { filterBoolean } from '@recruitee/common';
import { I18nService, TranslatePipe } from '@recruitee/i18n';
import {
  Breakpoint,
  IconName,
  SelectComponent,
  SelectOption,
  IconButtonComponent,
  TabComponent,
  TabsComponent,
  IconComponent,
  IconColorDirective,
  AvatarComponent,
  ButtonDropdownIndicatorDirective,
  ButtonComponent,
  DividerComponent,
} from '@recruitee/user-interface';

import { CoreEntityActions } from '../../../features/core/data/store/core.store';
import {
  MainViewEntityActions,
  mainViewSelectors,
} from '../../../features/main-view/data/store/main-view.store';
import {
  HeaderMenuContext,
  HeaderMenuContextType,
  IMainViewState,
} from '../../../features/main-view/data/types';
import { profileSelectors } from '../../../features/profile/data/store/profile.store';
import { ReferralsPortalProfile } from '../../../features/profile/data/types';
import { programSelectors } from '../../../features/program/data/store/program.store';
import { ReferralsPortalProgram } from '../../../features/program/data/types';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { SignInButtonComponent } from './sign-in-button/sign-in-button.component';
import { SignUpButtonComponent } from './sign-up-button/sign-up-button.component';

type HeaderSelectOption = SelectOption<() => void> & { icon: IconName };

@Component({
  selector: 'rtr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    ButtonDropdownIndicatorDirective,
    AvatarComponent,
    IconColorDirective,
    IconComponent,
    TabsComponent,
    TabComponent,
    RouterLink,
    RouterLinkActive,
    IconButtonComponent,
    SelectComponent,
    SignInButtonComponent,
    SignUpButtonComponent,
    HeaderLogoComponent,
    NgTemplateOutlet,
    DividerComponent,
    TranslatePipe,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public program: ReferralsPortalProgram;
  public headerContext: HeaderMenuContextType;
  public userName: string;
  public profile: ReferralsPortalProfile;
  public sidebarOpen: boolean;
  public HeaderMenuContext: any = HeaderMenuContext;
  public profileDropdownOptions: HeaderSelectOption[] = [];
  public sigInButtonActive: boolean;

  @Output() public logOut: EventEmitter<void> = new EventEmitter();

  public program$: Observable<ReferralsPortalProgram> = this.store
    .select(programSelectors.data)
    .pipe(filterBoolean);
  public profile$: Observable<ReferralsPortalProfile> = this.store
    .select(profileSelectors.data)
    .pipe(filterBoolean);
  public mainView$: Observable<IMainViewState> = this.store
    .select(mainViewSelectors.data)
    .pipe(filterBoolean);

  @ViewChild(SelectComponent, { static: true })
  private selectComponent: SelectComponent<HeaderSelectOption>;

  @ViewChildren(RouterLinkActive)
  private routerLinkActiveQuery: QueryList<RouterLinkActive>;

  private ngOnDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
    private i18nService: I18nService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {}

  // eslint-disable-next-line max-lines-per-function
  public ngOnInit(): void {
    this.program$
      .pipe(filterBoolean, takeUntil(this.ngOnDestroy$.asObservable()))
      .subscribe(program => {
        this.program = program;
        this.cdRef.detectChanges();
      });

    this.profile$.pipe(filterBoolean, takeUntil(this.ngOnDestroy$)).subscribe(profile => {
      this.userName = `${profile.firstName} ${profile.lastName}`;
      this.profileDropdownOptions = this.createDropdownOptions(profile.isAdmin);
      this.profile = profile;
      this.cdRef.detectChanges();
    });

    this.mainView$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(mainViewState => {
      this.sidebarOpen = mainViewState.sidebarOpen;
      this.headerContext = mainViewState.headerMenuContext;
      this.cdRef.detectChanges();
    });

    if (this.selectComponent)
      this.breakpointObserver
        .observe([Breakpoint.TABLET_DOWN])
        .pipe(takeUntil(this.ngOnDestroy$))
        .subscribe(result => {
          if (result.breakpoints[Breakpoint.TABLET_DOWN] && this.selectComponent.dropdownOpened) {
            this.selectComponent.toggleDropdown(false);
            this.cdRef.detectChanges();
          }
        });

    this.router.events
      .pipe(
        filter(event => event instanceof ChildActivationEnd),
        tap(e => {
          this.routerLinkActiveQuery.toArray().forEach(routerLink => routerLink.ngOnChanges({}));
        }),
        map(() => !this.router.url.includes('sign_in')),
        takeUntil(this.ngOnDestroy$.asObservable()),
      )
      .subscribe(sigInButtonActive => {
        this.sigInButtonActive = sigInButtonActive;
        this.cdRef.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  public toggleSidebar(): void {
    this.store.dispatch(MainViewEntityActions.toggleSidebar());
  }

  public dispatch(action: any): void {
    this.store.dispatch(action);
  }

  public onSelect(callback: Function): void {
    callback();
  }

  private navigateToProfile(): void {
    this.router.navigate(['/profile']);
  }

  private navigateToRecruitee(): void {
    this.store.dispatch(CoreEntityActions.navigateToRecruitee());
  }

  private createDropdownOptions(
    isAdmin: boolean,
  ): Array<SelectOption<() => void> & { icon: IconName }> {
    return [
      {
        label: this.i18nService.translate('rtr.header_profile.view_profile'),
        icon: 'person',
        value: this.navigateToProfile.bind(this),
      },
      ...(isAdmin
        ? [
            <const>{
              label: this.i18nService.translate('rtr.common.open_recruitee'),
              icon: 'open-in-new',
              value: this.navigateToRecruitee.bind(this),
            },
          ]
        : []),
      {
        label: this.i18nService.translate('rtr.header_profile.log_out'),
        icon: 'power-settings-new',
        value: () => this.logOut.emit(),
      },
    ];
  }
}
