import { createSingleEntityStore } from '@recruitee/ngrx';

import { ICoreState } from '../../../core/data/types';
import { EntityStoreKey, FeatureDiscoveryPayloadI } from '../types';

const { actions, selectors, reducer, namespace } = createSingleEntityStore<ICoreState>()(
  EntityStoreKey.featureDiscovery,
  {
    customActions: adapter => ({
      markFeatureAsDiscovered: (state, payload: FeatureDiscoveryPayloadI) =>
        adapter.markAsPending(state),
      markFeatureAsDiscoveredSuccess: (state, next) => adapter.markAsLoaded(next),
    }),
  },
);

export {
  actions as FeatureDiscoveryEntityActions,
  reducer as featureDiscoveryReducer,
  selectors as featureDiscoverySelectors,
  namespace as FeatureDiscoveryNamespace,
};
