import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { filterBoolean } from '@recruitee/common';
import { I18nService } from '@recruitee/i18n';
import type { ReferralsTerm } from '@recruitee/referrals-types';
import { ReferralTermKind } from '@recruitee/referrals-types';
import { DialogService, DialogWidth } from '@recruitee/user-interface';

import { termsSelectors } from '../../features/terms/data/store/terms.store';
import { TermsDialogComponent } from '../main-view/terms-dialog/terms-dialog.component';

@Injectable({ providedIn: 'root' })
export class TermsConditionsService {
  public termsConditions$: Observable<ReferralsTerm | null>;
  public privacyPolicy$: Observable<ReferralsTerm | null>;

  public terms$: Observable<Array<Required<ReferralsTerm>>> = this.store.select(
    termsSelectors.entities(),
  );

  constructor(
    private i18nService: I18nService,
    private dialogService: DialogService,
    private store: Store,
  ) {
    this.init();
  }

  public openTermsConditions(): void {
    this.termsConditions$
      .pipe(first(), filterBoolean)
      .subscribe(terms =>
        this.openTerm(
          terms,
          this.i18nService.translate('rtr.homepage.footer.terms_and_conditions'),
        ),
      );
  }

  public openPrivacyPolicy(): void {
    this.privacyPolicy$
      .pipe(first(), filterBoolean)
      .subscribe(privacy =>
        this.openTerm(privacy, this.i18nService.translate('rtr.homepage.footer.privacy_policy')),
      );
  }

  private init(): void {
    const terms$ = this.terms$.pipe(filterBoolean);
    this.termsConditions$ = terms$.pipe(
      map(terms => terms.find(term => term.kind === ReferralTermKind.TermsConditions) || null),
    );
    this.privacyPolicy$ = terms$.pipe(
      map(terms => terms.find(term => term.kind === ReferralTermKind.PrivacyPolicy) || null),
    );
  }

  private openTerm(term: ReferralsTerm, title: string): void {
    if (term.linkEnabled) {
      window.open(term.sourceLink, '_blank');
    } else {
      this.dialogService.openCustom(
        TermsDialogComponent,
        {
          width: DialogWidth.Small,
        },
        { title, body: term.bodyHtml },
      );
    }
  }
}
