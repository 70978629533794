import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { Ability } from '@recruitee/abilities-types';
import { fileToBase64, filterBoolean } from '@recruitee/common';
import { I18nService, TranslatePipe } from '@recruitee/i18n';
import {
  DialogService,
  NotificationsController,
  DropdownWidth,
  IconColorDirective,
  DropdownItemComponent,
  DropdownComponent,
  IconButtonComponent,
  TruncateComponent,
  IconComponent,
  OverlayOriginDirective,
  ConnectedOverlayDirective,
} from '@recruitee/user-interface';

import { profileSelectors } from '../../../../features/profile/data/store/profile.store';
import { ReferralsPortalProfile } from '../../../../features/profile/data/types';
import { ProgramEntityActions } from '../../../../features/program/data/store/program.store';

@Component({
  selector: 'rtr-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.less'],
  standalone: true,
  imports: [
    RouterLink,
    TruncateComponent,
    NgTemplateOutlet,
    IconButtonComponent,
    OverlayOriginDirective,
    ConnectedOverlayDirective,
    DropdownComponent,
    DropdownItemComponent,
    IconColorDirective,
    IconComponent,
    TranslatePipe,
  ],
})
export class HeaderLogoComponent implements OnInit, OnDestroy {
  @Input() public logoUrl: string;
  @Input() public companyName: string;

  public isAdmin: boolean = false;
  public logoEditOpen: boolean = false;
  public uploading: boolean = false;
  public deleting: boolean = false;
  public canEdit: boolean = false;
  public DropdownWidth: typeof DropdownWidth = DropdownWidth;

  public profile$: Observable<ReferralsPortalProfile | null> = this.store.select(
    profileSelectors.data,
  );

  private ngOnDestroy$ = new Subject<void>();

  private successMessage: { create: string; update: string; delete: string } = {
    create: this.i18n.translate('rtr.homepage.logo.create_success_notification_message'),
    update: this.i18n.translate('rtr.homepage.logo.update_success_notification_message'),
    delete: this.i18n.translate('rtr.homepage.logo.delete_success_notification_message'),
  };

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
    private i18n: I18nService,
    private dialogService: DialogService,
    private notificationsController: NotificationsController,
    private actions$: Actions,
  ) {}

  public ngOnInit(): void {
    this.profile$
      .pipe(
        filterBoolean,
        map(profile => profile.isAdmin),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe(isAdmin => {
        this.isAdmin = isAdmin;
        this.cdRef.detectChanges();
      });

    this.store
      .select(profileSelectors.abilitiesHasOne(Ability.ManageReferrals))
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(canManageReferrals => {
        this.canEdit = canManageReferrals;
        this.cdRef.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  public toggleLogoEdit(): void {
    this.logoEditOpen = !this.logoEditOpen;
    this.cdRef.detectChanges();
  }

  public upload(files: FileList | null, fileInput: any): void {
    if (files && files.length > 0) {
      this.uploading = true;
      this.logoEditOpen = true;
      this.cdRef.detectChanges();

      const hasLogo = <boolean>!!this.logoUrl;

      fileToBase64(files[0])
        .pipe(take(1), takeUntil(this.ngOnDestroy$))
        .subscribe(base64 =>
          this.store.dispatch(ProgramEntityActions.updateProgram({ payload: { logo: base64 } })),
        );

      this.actions$
        .pipe(ofType(ProgramEntityActions.updateProgramSuccess), take(1))
        .subscribe(_ => {
          this.uploading = false;
          this.logoEditOpen = false;
          this.notificationsController.success(
            hasLogo ? this.successMessage.update : this.successMessage.create,
          );
          this.cdRef.detectChanges();
        });
    }
    fileInput.value = null;
  }

  public delete(): void {
    const title = this.i18n.translate('rtr.header.logo_delete.title');
    const message = this.i18n.translate('rtr.header.logo_delete.description');
    this.deleting = true;
    this.logoEditOpen = true;
    this.cdRef.detectChanges();
    this.dialogService.confirm({ title, message, variant: 'error' }).subscribe(confirmed => {
      if (confirmed) {
        this.deleting = false;
        this.logoEditOpen = false;
        this.cdRef.detectChanges();
        this.store.dispatch(ProgramEntityActions.updateProgram({ payload: { logo: null } }));
        this.notificationsController.success(this.successMessage.delete);
      }
    });
  }
}
