import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TranslatePipe } from '@recruitee/i18n';

@Component({
  selector: 'rtr-sign-up-button',
  templateUrl: './sign-up-button.component.html',
  styleUrls: ['./sign-up-button.component.less'],
  standalone: true,
  imports: [RouterLink, TranslatePipe],
})
export class SignUpButtonComponent {}
