<div class="sidebar-items-wrap">
  <div layout-vertical>
    <rt-heading
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeSidebar()"
      variant="heading large-xx"
      routerLink="/"
      routerLinkActive="active"
      class="sidebar-item"
    >
      <a> {{ 'rtr.homepage.menu.jobs' | translate }} </a>
    </rt-heading>
    <rt-heading
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeSidebar()"
      variant="heading large-xx"
      routerLink="/referrals"
      routerLinkActive="active"
      class="sidebar-item"
    >
      <a> {{ 'rtr.homepage.menu.referrals' | translate }} </a>
    </rt-heading>
    <rt-heading
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeSidebar()"
      variant="heading large-xx"
      routerLink="/how-it-works"
      routerLinkActive="active"
      class="sidebar-item"
    >
      <a> {{ 'rtr.homepage.menu.how_it_works' | translate }} </a>
    </rt-heading>
    <rt-heading
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeSidebar()"
      variant="heading large-xx"
      routerLink="/profile"
      routerLinkActive="active"
      class="sidebar-item"
    >
      <a> {{ 'rtr.homepage.menu.my_profile' | translate }} </a>
    </rt-heading>
  </div>

  <rt-heading (click)="logOut.emit()" variant="heading large-xx" class="sidebar-item logout">
    <a> {{ 'rtr.homepage.menu.logout' | translate }} </a>
  </rt-heading>
</div>
