<rt-divider></rt-divider>
<footer>
  <div class="footer-actions">
    @if (privacy) {
      <a class="privacy" (click)="onPrivacyClick()">
        {{ 'rtr.homepage.footer.privacy_policy' | translate }}
      </a>
      <span class="dot">•</span>
    }
    @if (terms) {
      <a class="terms" (click)="onTermsClick()">
        {{ 'rtr.homepage.footer.terms_and_conditions' | translate }}
      </a>
      <span class="dot">•</span>
    }
    <a class="help" [href]="ExternalUrl.Footer" target="_blank">
      {{ 'rtr.homepage.footer.help_center' | translate }}
    </a>
    <a
      class="hiring"
      [href]="ExternalUrl.RecruiteeLandingPage"
      target="_blank"
      layout
      layout-align-items-center
    >
      <span margin-right-small-xx>{{ 'rtr.homepage.footer.hiring_with' | translate }}</span>
      <rt-icon name="logo__horizontal--grey"></rt-icon>
    </a>
  </div>
</footer>
