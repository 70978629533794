<ng-template #logoViewTemplate>
  @if (logoUrl) {
    <div routerLink="/" class="img-wrap" [attr.tabindex]="null">
      <img [src]="logoUrl" />
    </div>
  } @else {
    <div class="company-name" routerLink="/" [attr.tabindex]="null">
      <rt-truncate>
        {{ companyName }}
      </rt-truncate>
    </div>
  }
</ng-template>

@if (!canEdit) {
  <div class="logo-container">
    <ng-container [ngTemplateOutlet]="logoViewTemplate"></ng-container>
  </div>
} @else {
  <div class="logo-container">
    <ng-container [ngTemplateOutlet]="logoViewTemplate"></ng-container>
    <rt-icon-button
      #actionsDropdownTrigger="rtOverlayOrigin"
      [pressed]="actionsDropdown.open"
      (click)="actionsDropdown.open = !actionsDropdown.open"
      class="edit-button"
      rt-overlay-origin
      margin-left-small
      variant="tertiary"
      shape="square"
      iconName="edit"
    >
    </rt-icon-button>
    <ng-template
      #actionsDropdown="rtConnectedOverlay"
      rt-connected-overlay
      hasBackdrop
      [origin]="actionsDropdownTrigger"
      (backdropClick)="actionsDropdown.open = false"
    >
      <rt-dropdown [width]="DropdownWidth.SmallX">
        <rt-dropdown-item (click)="fileInput.click()">
          <rt-icon prefix name="file-upload"></rt-icon>
          {{ 'rtr.header.upload_new_logo' | translate }}
        </rt-dropdown-item>
        @if (logoUrl) {
          <rt-dropdown-item (click)="delete()">
            <rt-icon prefix name="delete"></rt-icon>
            {{ 'rtr.header.delete_logo' | translate }}
          </rt-dropdown-item>
        }
      </rt-dropdown>
    </ng-template>
    <input
      (change)="upload(fileInput.files, fileInput)"
      #fileInput
      type="file"
      accept="image/*"
      hidden
    />
  </div>
}
