import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslatePipe } from '@recruitee/i18n';
import {
  DialogComponentI,
  DialogRef,
  ButtonComponent,
  DividerComponent,
  BoxComponent,
  DialogHeaderComponent,
} from '@recruitee/user-interface';

@Component({
  selector: 'rtr-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.less'],
  standalone: true,
  imports: [DialogHeaderComponent, BoxComponent, DividerComponent, ButtonComponent, TranslatePipe],
})
export class TermsDialogComponent
  implements DialogComponentI<{ title: string; body: string }, null>
{
  public title: string;
  public body: SafeHtml;

  constructor(
    private dialogRef: DialogRef<TermsDialogComponent>,
    private sanitizer: DomSanitizer,
  ) {}

  public setDialogData(data: { title: string; body: string }, triggerUpdate: boolean): void {
    this.title = data.title;
    // eslint-disable-next-line ban/ban
    this.body = this.sanitizer.bypassSecurityTrustHtml(data.body);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
