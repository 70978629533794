import { Injectable } from '@angular/core';

import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  public scrollingDown$: Observable<boolean> = fromEvent(window, 'scroll').pipe(
    map(_ => window.scrollY),
    pairwise(),
    debounceTime(10),
    map(([a, b]) => !(a - b > 0)),
  );
}
