import { createSingleEntityStore, pipeState } from '@recruitee/ngrx';

import { EntityStoreKey, ReferralsPortalProgram, ReferralsPortalProgramPayload } from '../types';

const { actions, selectors, reducer, namespace } =
  createSingleEntityStore<ReferralsPortalProgram>()(EntityStoreKey.program, {
    customActions: adapter => ({
      updateProgram: (state, payload: ReferralsPortalProgramPayload) =>
        adapter.markAsPending(state),
      updateProgramSuccess: (state, payload: ReferralsPortalProgram) =>
        pipeState(adapter.save(state, payload), next => adapter.markAsLoaded(next)),
    }),
  });

export {
  actions as ProgramEntityActions,
  reducer as programReducer,
  selectors as programSelectors,
  namespace as ProgramNamespace,
};
